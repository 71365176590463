<template>
    <div class="wrapper">
        <div class="scroll-text">
            <h2>Credits</h2>
            <div class="credits-list">
                <div v-for="(credit, key, index) in credits" :key="index">
                    <h3>{{ key }}</h3>
                    <p v-for="(name, index) in credit" :key="index">
                        {{ name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const router = useRouter()
        const credits = ref({
            Idee: ['Matthias K.'],
            Grafik: ['Nicole R.-S.'],
            Texte: ['Tanja K.'],
            Programmierung: ['Matthias M.', 'Samuel B.', 'Joscha H. P.'],
            'Besonderen Dank': [
                'Hannah Wedemeyer',
                'Sven Kahrs',
            ],
        })

        onMounted(() => {
            setTimeout(() => {
                router.push(`/bewerten`)
            }, 60000)
        })

        return { credits }
    },
})
</script>

<style scoped>
@keyframes scrollAnimation {
    from {
        transform: translateY(calc(100% - 90px));
    }
    to {
        transform: translateY(-7500px);
    }
}

.wrapper {
    position: relative;
    display: flex;
    z-index: -1;
    height: calc(100vh - 90px);
    width: 60%;
    margin: 0 auto;
    perspective: 450px;
    justify-content: center;
}

.scroll-text {
    position: relative;
    font-size: 3vw;
    line-height: 1.5em;
    font-weight: bold;
    text-align: center;
    animation: scrollAnimation 120s normal forwards linear;
}

p {
    font-size: 2.5vw;
}

li {
    list-style: none;
}
</style>
